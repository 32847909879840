<template>
  <div name="unitview">
    <innertopbar @close="closeWindow()">
     <template slot="breadcrumb">
        <router-link to="/"> Planning </router-link>
        <i class="carret fas fa-caret-right"></i>
        Y{{ year.letter }} <i class="fas fa-caret-right opacity-25 px-1"></i>
        {{ course.name }}
        <i class="fas fa-caret-right opacity-25 px-1"></i> {{ title }}
      </template>

      <template slot="listitems">
        <li class="inline-block">
          <dropdown
            v-if="classes.length > 0"
            :options="classDropdown"
            :css="'text-white'"
            :selected="selectedClass"
            v-on:updateOption="classSelect"
            class="publish-button hover:bg-opacity-75 w-48 text-center"
          ></dropdown>
        </li>
      </template>
    </innertopbar>

    <div class="filter-bar h-20">
      <ul class="actions">
        <li class="opacity-75">Lessons:</li>
        <li class="inline-block">
          <dropdown
          :options="unitOpionDropdown"
            :css="'text-action-blue'"
            :selected="selectedUnitOption"
            :key="selectedUnitOption.id"
            v-on:updateOption="lessonSelect"
            class="
              relative
              cursor-pointer
              h-10
              mt-1
              rounded
              p-2
              bg-opacity-25 
              publish-button
              bg-gray-500
              w-40
              text-center
              inline-block
            "
             ></dropdown>
           
  
        </li>
        <li class="opacity-75 ml-1">Actions:</li>

   

        <li
          @click="setBaseView()"
          class="action-list-item inline-block hover:opacity-100 pad-right text-sm"
          v-bind:class="isBaseContent 
              ? 'bg-gray-400 font-semibold '
              : 'text-gray-600 bg-list-grey font-normal '
          "
        >
          <i class="fas fa-file-alt pt-4" title="Lesson Content"></i>
           <p class="inline text-xs p-1">Content</p>
        </li>

                 <li
          v-if="course.type_id === 1"
          @click="showSKAModal = true"
          class="action-list-item hover:opacity-100 text-gray-600 bg-list-grey pad-right text-sm "
        >
            <i class="fas fa-basketball-ball pt-4 " title="SKA Information"></i>
             <p class="inline text-xs  p-1">Resources</p>
        </li>

        <li
          v-show="!overviewInDisplay"
          @click="showAttendance()"
          class="action-list-item hover:opacity-100 pad-right text-sm"
          v-bind:class="
            displaySelected == 'attendance'
              ? 'bg-gray-400 font-semibold '
              : 'text-gray-600 bg-list-grey font-normal '
          "
        >
          <i class="fas fa-calendar-alt pt-4" title="Attendance"></i>
           <p class="inline text-xs p-1">Attendance</p>
        </li>
        <li
          @click="showAssessment()"
          class="action-list-item hover:opacity-100 pad-right text-sm"
          v-bind:class="
            displaySelected == 'assessment'
              ? 'bg-gray-400 font-semibold '
              : 'text-gray-600 bg-list-grey font-normal '
          "
        >
          <i class=" fas fa-star-half-alt pt-4" title="Assessment"></i>
           <p class="inline text-xs p-1">Assessment</p>
        </li>

        <li
          @click="showOutcomes()"
          class="action-list-item hover:opacity-100 pad-right text-sm"
          v-bind:class="
            displaySelected == 'outcomes'
                ? 'bg-gray-400 font-semibold '
              : 'text-gray-600 bg-list-grey font-normal '
          "
        >
          <i class=" fas fa-list-ol pt-4" title="Outcomes"></i>
            <p class="inline text-xs p-1">Outcomes</p>
        </li>

        <li
          @click="showEvidence()"
          class="action-list-item hover:opacity-100 pad-right text-sm"
          v-bind:class="
            displaySelected == 'evidence'
                ? 'bg-gray-400 font-semibold '
              : 'text-gray-600 bg-list-grey font-normal '
          "
        >
          <i class="fas fa-images pt-4" title="Evidence"></i> 
          <p class="inline text-xs p-1">Evidence</p>
        </li>

      </ul>
    </div>

    <div class="w-full mx-auto">
      <div class="flex flex-col md:flex-row">
        <div
          i
          class="
            w-95p
            rounded-lg
            h-screen
            overflow-y-scroll
            bg-white
            mt-2
            pb-4
            mx-auto
          "
          id="infinite-list"
        >
          <div class="mt-4 w-ful mx-6 mr-10">
            <div class="flex-row">
              <img
                v-if="course.styles.String == 'pippaeddie'"
                src="@/assets/images/pippaeddie-logo.png"
                style="width: 100px"
                class="inline-block mb-4"
              />
              <h1
                class="
                  text-2xl
                  m-5
                  mt-2
                  font-bold
                  text-gray-700
                  capitalize
                  inline-block
                "
              >
                <span
                  v-if="course.styles.String == 'pippaeddie'"
                  class="text-lg"
                  >EYFS Pippa &amp; Eddie <br /></span
                >{{ contentTitle }}
              </h1>
            </div>

            <div v-if="errored" class="empty">
              <p>
                We are not able to retrieve this information at the moment,
                please try back later
              </p>
            </div>

            <div id="content-area" v-else>
              <div style="width: 100%; text-align: center" v-if="loading">
                <i class="spinner fa fa-spinner fa-spin"></i>
              </div>

              <courseOverview
                v-if="overviewInDisplay"
                v-show="displaySelected == 'overview'"
                :course="course"
                :lessons="lessons"
                v-on:showLesson="lessonSelectionFromOverview"
              >
              </courseOverview>

              <p v-if="!overviewInDisplay && lessonCount === 0">
                The selected unit has no lesson plans available. Please select a
                different unit of work.
              </p>

SL {{selectedLesson}} <br/>
DP {{displaySelected}}
<br/>
              <Lesson
                :lesson="selectedLesson"
                :key="'lessoncontent' + selectedLesson.id"
                ref="refLesson"
                v-if="selectedLesson != undefined"
                v-show="displaySelected === 'lessoncontent'"
              ></Lesson>
            </div>

            <Attendance
              :lesson="selectedLesson"
              :key="'attendance' + selectedLesson.id"
              :pupils="filteredPupils"
              v-if="selectedLesson != undefined"
              v-show="displaySelected == 'attendance'"
            ></Attendance>

            <Assessment
              :course="course"
              :key="'assessment' + course.id"
              :classSelected="classIsSelected"
              :pupils="filteredPupils"
              v-if="displaySelected == 'assessment'"
              v-show="displaySelected == 'assessment'"
            ></Assessment>

            <Outcomes
              :course="course"
              :key="'outcomes' + course.id"
              :pupils="filteredPupils"
              v-if="displaySelected == 'outcomes'"
              v-show="displaySelected == 'outcomes'"
            ></Outcomes>

            <Evidence
              :key="'evidence' + course.id"
              :course="course"
              :pupils="filteredPupils"
              :selectedClass="selectedClass"
              :selectedLesson="selectedLesson"
              :lessons="lessons"
              :classes="classes"
              v-if="displaySelected == 'evidence'"
              v-show="displaySelected == 'evidence'"
              ref="evidenceRef"
            ></Evidence>
          </div>
        </div>
      </div>
    </div>

    <modalfile
      :url="loadSKAFile()"
      @close="showSKAModal = false"
      v-if="showSKAModal"
      v-show="showSKAModal"
    ></modalfile>
  </div>
</template>

<script>
const innertopbar = () => import("@/components/InnerTopBar.vue");

const courseOverview = () =>
  import("@/components/curriculum/TheCourseOverview.vue");
const popupScreen = () => import("@/components/LessonContentModal.vue");
const modalfile = () => import("@/components/ModalFile.vue");
const Lesson = () => import("@/components/curriculum/LessonView.vue");
const Attendance = () =>
  import("@/components/curriculum/TheLessonAttendance.vue");
const Assessment = () =>
  import("@/components/curriculum/TheCourseAssessment.vue");
const Evidence = () => import("@/components/curriculum/TheLessonEvidence.vue");
const Outcomes = () => import("@/components/curriculum/TheCourseOutcomes.vue");
const dropdown = () => import("@/components/Dropdown.vue");
const DisplayOptions = {
  OVERVIEW: "overview",
  CONTENT: "lessoncontent",
  ATTENDANCE: "attendance",
  ASSESSMENT: "assessment",
  EVIDENCE: "evidence",
  OUTCOMES: "outcomes",
};

import { mapGetters } from "vuex";
import { SchoolService } from "@/services/school.service";
import { yearGroupMixin } from "@/mixins/yearGroupMixin.js";

export default {
  name: "unitview",
  mixins: [yearGroupMixin],
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    lessonCount() {
      return this.lessons.length;
    },
    componentLoaded() {
      return this.lessons.length > 0;
    },
    orderedLessons() {
      return this.$orderBy(this.lessons, ["order", "name"]);
    },
    showBarSuffix() {
      return (
        this.displaySelected != DisplayOptions.ASSESSMENT &&
        !this.overviewInDisplay
      );
    },
    isBaseContent() {
      return [DisplayOptions.OVERVIEW, DisplayOptions.CONTENT].includes(this.displaySelected)
    },
    title() {
      if (
        this.selectedLesson != undefined &&
        this.displaySelected === DisplayOptions.CONTENT
      ) {
        return this.selectedLesson.name;
      } else {
        return this.displaySelected
      }
    },
    contentTitle() {
      if (this.selectedLesson != undefined && this.displaySelected === DisplayOptions.CONTENT) {
        return this.selectedLesson.name 
       } else if (this.displaySelected === DisplayOptions.ATTENDANCE) {
           return this.selectedLesson.name + " " + this.displaySelected
      } else {
        return this.displaySelected
      }
    },
    overviewInDisplay() {
      return this.selectedLesson === undefined;
    },
    classDropdown() {
      var results = [...this.classes];
      results = [{ id: 0, name: "Select a class" }].concat(results);
      this.selectedClass = results[0];
      return results;
    },
    unitOpionDropdown() {
      var results = [...this.orderedLessons];
      results = [{ id: 0, name: "Unit Overview" }].concat(results);
      this.selectedUnitOption = results[0];
      return results;
    },
    filteredPupils() {
      var vm = this;
      let classChosen = vm.selectedClass;

      if (classChosen.id === 0) {
        return this.$orderBy(vm.pupils, ["name", "yeargroup", "class"]);
      } else {
        var filtered = vm.pupils.filter(function (pupil) {
          return pupil.class === classChosen.name;
        });

        return this.$orderBy(filtered, ["name", "yeargroup", "class"]);
      }
    },
    classIsSelected() {
      return this.selectedClass.id > 0;
    },
    year() {
      return this.years
        .filter((year) => {
          return this.course.year_id === year.id;
        })
        .shift();
    },
  },
  data() {
    return {
      course: this.$store.getters.unit,
      loading: true,
      errored: false,
      lessons: [],
      selectedLesson: undefined,
      showSKAModal: false,
      displaySelected: DisplayOptions.OVERVIEW,
      pupils: [],
      classes: [],
      selectedClass: {},
      selectedUnitOption: {},
    };
  },
  created() {
    this.loading = true;
    this.fetchData();

    SchoolService.getAll("classes")
      .then((response) => this.buildClassList(response.data))
      .catch((err) => {
        console.log("classes " + err);
        this.errored = true;
      });

    this.fetchPupils();
  },
  mounted() {
    const listElm = document.querySelector("#infinite-list");
    listElm.addEventListener("scroll", (e) => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        if (this.displaySelected === "evidence") {
          this.$refs.evidenceRef.loadMore();
        }
      }
    });
  },
  methods: {
    fetchData() {
      SchoolService.getAll("courses/" + this.course.id + "/lessons")
        .then((response) => this.buildLessonList(response.data))
        .catch((err) => {
          console.log("Lessons " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    buildLessonList(data) {
      this.lessons = data;
    },
    fetchPupils() {
      SchoolService.getAll("pupils")
        .then((response) => this.buildPupilList(response.data))
        .catch((err) => {
          console.log("Pupils " + err);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    buildPupilList(data) {
      this.pupils = data;
    },
    buildClassList(data) {
      this.classes = data.map(function (item, index) {
        return { id: index + 1, name: item };
      });
    },
    closeWindow() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
    checkNumber(lesson) {
      if (this.overviewInDisplay) {
        return "bg-green-400";
      } else if (lesson.id == this.selectedLesson.id) {
        return "bg-pink-red";
      } else if (lesson.order < this.selectedLesson.order) {
        return "bg-green-400 opacity-25";
      } else {
        return "bg-button-black";
      }
    },
    isLessonSelected(lesson) {
      if (this.selectedLesson == undefined) {
        return false;
      }
      return lesson.id == this.selectedLesson.id;
    },
    lessonSelectionFromOverview(value) {
      this.selectedUnitOption = value
      this.lessonSelect(value)
    },
    lessonSelect(value) {
      if (value.id === 0) {
        this.showOverview();
      } else {
        this.showContent();
        this.selectedLesson = value;

      }
    },
    loadSKAFile() {
      return (
        "ska/" +
        this.course.name
          .toLowerCase()
          .trim()
          .replace(/ -/g, "")
          .replace(/ &/g, "")
          .replace(/ /g, "_") +
        "_" +
        this.year.key_value +
        ".pdf"
      );
    },
    print() {
      this.$htmlToPaper("printMe");
      //window.print();
      //   var newWindow = window.open();
      //   newWindow.document.write(document.getElementById("output").innerHTML);
      //   newWindow.print();
    },
    setBaseView() {
      if (this.selectedLesson === undefined) {
        this.showOverview()
      } else {
        this.showContent()
      }
    },
    showOverview() {
      this.displaySelected = DisplayOptions.OVERVIEW;
      this.selectedLesson = undefined;
    },
    showContent() {
      this.displaySelected = DisplayOptions.CONTENT;
    },
    showAttendance() {
      this.displaySelected = DisplayOptions.ATTENDANCE;
    },
    showAssessment() {
      this.displaySelected = DisplayOptions.ASSESSMENT;
    },
    showOutcomes() {
      this.displaySelected = DisplayOptions.OUTCOMES;
    },
    showEvidence() {
      this.displaySelected = DisplayOptions.EVIDENCE;
    },
    classSelect(value) {
      this.selectedClass = value;
    },
    getBackColor() {
      return this.course.styles.String == "pippaeddie"
        ? "bg-pippa-green"
        : "bg-pink-red";
    },
  },
  components: {
    Lesson,
    Attendance,
    Assessment,
    Evidence,
    modalfile,
    Outcomes,
    dropdown,
    popupScreen,
    courseOverview,
    innertopbar,
  },
};
</script>

<style scoped>
.side-list-item {
  @apply p-4 px-2 mt-3 ml-4 mb-2 w-10/12 rounded-md font-medium cursor-pointer text-xs font-semibold;
}
</style>
